/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from "react";
// import Image from "next/image";
import { eventTracking } from "../../../firebase/firebaseConfig";

type TProps = {
  className?: string;
  style?: {
    display: string;
    width: number;
    height: number;
  };
  slot: any;
  layout?: string;
  layoutKey?: string;
  format?: string;
  responsive?: string;
  pageLevelAds?: boolean;
  adTypeTracking?: string;
  onCloseAd?: () => void;
  [x: string]: any;
};
declare global {
  interface Window {
    adsbygoogle: any;
  }
}
function GoogleAd({
  style = { display: "block", width: 0, height: 0 },
  slot,
  layout = "",
  layoutKey = "",
  format = "auto",
  responsive = "false",
  pageLevelAds = false,
  adTypeTracking = "",
  onCloseAd,
  ...rest
}: TProps) {
  const insRef = useRef<HTMLModElement>(null);

  useEffect(() => {
    const p: any = {};
    if (pageLevelAds) {
      p.google_ad_client = process.env.NEXT_PUBLIC_GOOGLE_AD_ID;
      p.enable_page_level_ads = true;
    }
    const ads = document.getElementsByClassName("adsbygoogle").length;
    for (let i = 0; i < ads; i++) {
      try {
        if (typeof window === "object") {
          (window.adsbygoogle = window.adsbygoogle || [])?.push(p);
        }
      } catch {
        // Pass
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isTracking =
      adTypeTracking !== "" && insRef.current && insRef.current.getAttribute("data-ad-status") === "filled";
    if (isTracking) {
      eventTracking(adTypeTracking, { device: navigator?.userAgent });
    }
  }, [adTypeTracking, insRef.current]);

  useEffect(() => {
    if (insRef.current && insRef.current.getAttribute("data-ad-status") === "unfilled") {
      onCloseAd?.();
      insRef.current.style.display = "none";
    }
  }, [insRef.current]);

  const isAdTestOn = !(process.env.NODE_ENV === "production" && process.env.NEXT_PUBLIC_GOOGLE_AD_TEST_ON === "0");
  if (isAdTestOn) {
    const configImgTest = (width: number, height: number) =>
      `https://dummyimage.com/${width}x${height}/000/fff&text=GoogleAdTest_${width}x${height}`;

    return <img style={{ borderRadius: 6 }} src={configImgTest(style.width, style.height)} alt="" />;
  }
  return (
    <ins
      ref={insRef}
      className="adsbygoogle"
      style={style}
      data-ad-client={process.env.NEXT_PUBLIC_GOOGLE_AD_ID}
      data-ad-slot={slot}
      data-ad-layout={layout}
      data-ad-layout-key={layoutKey}
      data-ad-format={format}
      data-full-width-responsive={responsive}
      {...rest}
    />
  );
}

export default GoogleAd;
